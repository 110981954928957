<template>
	<b-modal v-model="show" id="print-dispatch-summary" :title="title" size="xl" ref="print-dispatch-summary"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container v-show="selDispatch !== null">
			<div class="page-container" id="dispatch-summary-container">

				<!-- Dispatcher's Copy -->
				<div class="dispatch-summary">
					<!-- Header -->
					<DispatchSummaryHeader :selDispatch="selDispatch" />

					<!-- Source and Destination -->
					<DispatchSummarySourceAndDestination :selDispatch="selDispatch" :sourceLocation="sourceLocation" :destinationLocation="destinationLocation" />

					<!-- Assets -->
					<DispatchSummaryAssetTypes :selDispatch="selDispatch" />

					<!-- Remarks -->
					<b-row class="px-2 mb-4 remarks-section">
						<b-col sm="6" class="border border-light border-1 p-2">
							<span class="section-header">DISPATCH REMARKS:</span>&nbsp;
							<span class="remarks-value" v-html="dispatchRemarks"></span>
						</b-col>
						<b-col sm="6" class="border border-light border-1 p-2">
							<span class="section-header">RECEIPT REMARKS:</span>&nbsp;
							<span class="remarks-value" v-html="receiptRemarks"></span>
						</b-col>
					</b-row>

					<!-- Signatories -->
					<DispatchSummarySignatories :selDispatch="selDispatch" :deployedByUser="deployedByUser" :receivedByUser="receivedByUser" />

					<!-- Footer -->
					<DispatchSummaryFooter copyOwner="Dispatcher's" />
				</div>

				<!-- Divider Line -->
				<div class="divider-container">
					<i class="fa fa-scissors divider-icon"></i>
					<hr class="divider-line" />
				</div>

				<!-- Driver's Copy -->
				<div class="dispatch-summary">
					<!-- Header -->
					<DispatchSummaryHeader :selDispatch="selDispatch" />

					<!-- Source and Destination -->
					<DispatchSummarySourceAndDestination :selDispatch="selDispatch" :sourceLocation="sourceLocation" :destinationLocation="destinationLocation" />

					<!-- Assets -->
					<DispatchSummaryAssetTypes :selDispatch="selDispatch" />

					<!-- Remarks -->
					<b-row class="px-2 mt-2 mb-4 remarks-section">
						<b-col sm="6" class="border border-light border-1 p-2">
							<span class="section-header">DISPATCH REMARKS:</span>&nbsp;
							<span class="remarks-value" v-html="dispatchRemarks"></span>
						</b-col>
						<b-col sm="6" class="border border-light border-1 p-2">
							<span class="section-header">RECEIPT REMARKS:</span>&nbsp;
							<span class="remarks-value" v-html="receiptRemarks"></span>
						</b-col>
					</b-row>

					<!-- Signatories -->
					<DispatchSummarySignatories :selDispatch="selDispatch" :deployedByUser="deployedByUser" :receivedByUser="receivedByUser" />

					<!-- Footer -->
					<DispatchSummaryFooter copyOwner="Driver's" />
				</div>
			</div>
		</b-container>

		<template #modal-footer>
			<div class="w-100">
				<span class="float-right mr-3">
					<b-button variant="secondary" @click="show = false" class="footer-button mr-2"
						:disabled="disableConfirmButtons">
						Close
					</b-button>
					<b-button v-show="hasDispatchNo" variant="primary" @click="printDispatch" class="footer-button"
						:disabled="disableConfirmButtons">
						Print
					</b-button>
				</span>
			</div>
		</template>
	</b-modal>
</template>

<script>
// Components
import DispatchSummaryHeader from './summary/DispatchSummaryHeader';
import DispatchSummarySourceAndDestination from './summary/DispatchSummarySourceAndDestination';
import DispatchSummaryAssetTypes from './summary/DispatchSummaryAssetTypes';
import DispatchSummarySignatories from './summary/DispatchSummarySignatories';
import DispatchSummaryFooter from './summary/DispatchSummaryFooter';

// Util
import { DispatchUtil } from '@/utils/dispatchUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2pdf from 'html2pdf.js';
import _ from 'lodash';

export default {
	name: 'print-dispatch-summary',
	components: {
		DispatchSummaryHeader,
		DispatchSummarySourceAndDestination,
		DispatchSummaryAssetTypes,
		DispatchSummarySignatories,
		DispatchSummaryFooter,
		Loading,
	},
	props: {
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			show: false,
			selDispatch: {},
			sourceLocation: {},
			destinationLocation: {},
			deployedByUser: '',
			receivedByUser: '',

			configTimeout: 5000,
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'View Dispatch';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		dispatchRemarks() {
			let remarks = '-';
			let proofOfDispatch = this.selDispatch ? this.selDispatch.proofOfDispatch : null;

			if (proofOfDispatch) {
				remarks = proofOfDispatch.remarks && proofOfDispatch.remarks.length > 0 ? proofOfDispatch.remarks : '-';
			} else {
				let notes = this.selDispatch ? this.selDispatch.notes : null;
				remarks = notes && notes.length > 0 ? notes : '-';
			}

			return this.truncateRemarks(remarks);
		},
		receiptRemarks() {
			let remarks = '-';

			let proofOfReceipt = this.selDispatch.proofOfReceipt;
			if (proofOfReceipt) {
				remarks = proofOfReceipt.remarks && proofOfReceipt.remarks.length > 0 ? proofOfReceipt.remarks : '-';
			}

			return this.truncateRemarks(remarks);
		},
		dispatchNo() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			return dispatch.dispatchNo ? dispatch.dispatchNo : "-";
		},
		hasDispatchNo() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			return dispatch.dispatchNo && dispatch.dispatchNo.length > 0 ? true : false;
		},
		isDriverActive() {
			let driver = this.selDispatch && this.selDispatch.driver ? this.selDispatch.driver : {};
			return driver && driver.userId && driver.userId.length > 0 ? true : false;
		}
	},
	mounted() {
		EventBus.$on('onPrintDispatch', (selDispatch) => {
			this.onReset();

			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
				this.retrieveOtherDetails(selDispatch);
			}

			this.$bvModal.show('print-dispatch-summary');
		});
	},
	methods: {
		retrieveOtherDetails(selDispatch) {
			// Show loader
			this.isLoading = true;

			let sourceLocId = selDispatch.source.storageLocationId;
			this.sourceLocation = this.allStorageLocationsObj[sourceLocId];

			let destinationLocId = selDispatch.destination.storageLocationId;
			this.destinationLocation = this.allStorageLocationsObj[destinationLocId];

			if (this.isDriverActive || this.selDispatch.status === 'Draft') {
				this.deployedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.createdBy]);
			} else {
				this.deployedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.deployedBy]);
			}

			if (selDispatch.receivedBy && selDispatch.receivedBy.length > 0) {
				this.receivedByUser = this.getNameDisplay(this.allUsersObj[selDispatch.receivedBy]);
			}

			// Check for loader
			this.isLoading = false;
		},

		async printDispatch() {
			try {
				// Show loading indicator
				this.isLoading = true;

				// Generate PDF with two copies on one page
				await this.downloadDualCopy();

				// Hide modal and reset
				this.$bvModal.hide('print-dispatch-summary');
				this.onReset();
			} catch (error) {
				console.error('Error occurred:', error);
			} finally {
				// Hide loading indicator
				this.isLoading = false;
			}
		},

		async downloadDualCopy() {
			try {
				const container = document.getElementById('dispatch-summary-container');
				const filename = this.getFileName();

				// Create the PDF configuration
				const opt = {
					margin: [0, 0, 0, 0],
					filename: filename,
					image: { type: 'jpeg', quality: 1 },
					html2canvas: { scale: 2 },
					jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
				};

				// Capture the content and generate the PDF
				html2pdf().from(container).set(opt).toPdf().get('pdf').then(pdf => {
					pdf.save(filename);
				});

			} catch (error) {
				console.error('Error occurred during download:', error);
			} finally {
				// Hide modal and reset
				this.isLoading = false;
				this.$bvModal.hide('print-dispatch-summary');
				this.onReset();
			}
		},

		getFileName() {
			return this.dispatchNo;
		},
		onReset() {
			this.selDispatch = {};
			this.sourceLocation = {};
			this.destinationLocation = {};
			this.deployedByUser = '';
			this.receivedByUser = '';

			// Check for loader
			this.isLoading = false;
			this.show = true;
		},

		// UTILS
		getNameDisplay(userObj) {
			return userObj ? userObj.firstName + ' ' + userObj.lastName : 'TAWItech Support';
		},
		truncateRemarks(string) {
			if (string.length > 200) {
				let remarks = string.substring(0, 200) + '...';
				return this.breakRemarks(remarks, 50);
			}
			return this.breakRemarks(string, 50);
		},
		breakRemarks(remarks, length) {
			return remarks.length > length ? remarks.replace(new RegExp(`([^\\s]{${length}})`, 'g'), '$1<br>') : remarks;
		}
	},
	beforeDestroy() {
		EventBus.$off('onPrintDispatch');
	},
};
</script>

<style scoped>
.page-container {
	padding-top: 1em;
	padding-left: 1.75em;
	padding-right: 1.75em;
	position: relative;
	max-width: 100wh;
	max-height: 100%;
	aspect-ratio: 13 / 9;
	overflow: visible;
}

.section-header {
	text-transform: uppercase;
	color: #122C91;
	font-size: .7rem;
	font-style: normal;
	font-weight: bold;
	line-height: normal;
}

.remarks-section {
	height: 4rem;
}

.remarks-value {
	font-size: .7rem;
	font-style: italic;
	background-color: #BFDFCD;
	display: inline-block;
}

.divider-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -0.9rem;
	margin-left: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: 0.5rem;
}

.divider-icon {
	font-size: 0.75rem;
}

.divider-line {
	flex-grow: 1;
	border: 0;
	border-top: 0.1px dashed #1f1f1f;
	margin: 0;
}
</style>
